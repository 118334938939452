import React, { Component } from 'react'
import moment from 'moment'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'

import Logo from './logo'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Footer extends Component {

  state = {
    time: '',
    email: '',
    fname: '',
    lname: '',
    result: '',
    msg: '',
    newsletterActive: false
  }

  constructor(props) {
    super(props);

    this._toggleNewsletter = this._toggleNewsletter.bind(this);
  }

  startTime() {
    this.setState({ time: moment().format('MMMM Do YYYY, h:mm:ss') })
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.startTime(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  // 2. via `async/await`
  _handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    if (this.state.email === '') {
      if ( this.state.email === '' ) {
        this.setState({result: 'fail', msg: 'Please enter an email address.' })
      } else {
        this.setState({result: 'fail', msg: '' })
      }
    } else {
      fetch("/?no-cache=1", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": form.getAttribute("name"),
            ...this.state
          })
        })
          .then(() => this.setState({result: 'success', msg: 'Thanks for subscribing.'}))
          .catch(error => this.setState({result: 'fail', msg: error}));
    }

  }

  _toggleNewsletter() {
    if ( this.state.newsletterActive === true ) {
      this.setState({newsletterActive: false});
    } else {
      this.setState({newsletterActive: true});
      // Scroll to a certain element
      setTimeout(function(){
        window.scrollBy({
          top: 1000, // could be negative value
          left: 0,
          behavior: 'smooth'
        });
      }, 300);
    }
  }

  render() {
    let color = typeof window !== 'undefined' && window.location.pathname === '/process' ? '#FFFFFF':'#191919'
    return (
      <div>
        <Fade bottom distance="40px">
          <footer className="footer">
            <div className="footer__inner">
              <div className="row row--top">
                <div className="col">
                  <h4>We are always happy to meet new people. For any enquiries, please get in touch.</h4>
                </div>
                <div className="col">
                  <Logo color={color} />
                </div>
              </div>
              <div className="row row--top">
                <h3 className="headquaters">Australian Headquarters:</h3>
              </div>
              <div className="row row--middle">
                <div className="col">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                    <g fill={color} fillRule="evenodd">
                      <path id="a" d="M0 5.132c0-.91.252-1.83.757-2.76.554-1 1.079-1.604 1.573-1.812A5.897 5.897 0 0 1 3.347.248C3.777.15 4.127.1 4.394.1c.138 0 .242.015.311.045.179.059.44.435.787 1.128.109.188.257.455.445.801.189.347.362.66.52.943.158.282.312.547.46.794.03.04.116.163.26.371.143.208.25.383.32.527.068.143.103.284.103.423 0 .198-.141.445-.423.742a7.236 7.236 0 0 1-.92.817 8.94 8.94 0 0 0-.92.786c-.283.277-.424.505-.424.683 0 .09.025.2.074.334.05.134.092.235.127.304.034.07.104.188.207.357.104.168.161.262.171.282.752 1.355 1.613 2.518 2.583 3.488.97.97 2.133 1.83 3.488 2.583.02.01.114.067.282.17.169.104.287.174.357.208.069.035.17.077.304.127.133.049.245.074.334.074.178 0 .406-.141.683-.423a8.94 8.94 0 0 0 .786-.92c.248-.332.52-.639.817-.921.297-.282.544-.423.742-.423.139 0 .28.035.423.104.144.07.32.176.527.319.208.144.331.23.371.26.247.148.512.302.794.46.282.158.596.331.943.52.346.188.613.336.801.445.693.346 1.07.608 1.128.787.03.069.045.173.045.311 0 .267-.05.616-.148 1.047-.1.43-.203.77-.312 1.017-.208.494-.812 1.019-1.811 1.573-.93.505-1.85.757-2.761.757-.267 0-.527-.017-.78-.052a6.416 6.416 0 0 1-.853-.186 12.1 12.1 0 0 1-.705-.215 58.453 58.453 0 0 1-.824-.304 31.626 31.626 0 0 0-.727-.267 13.444 13.444 0 0 1-2.598-1.232c-1.266-.782-2.575-1.848-3.926-3.2-1.35-1.35-2.417-2.659-3.199-3.925A13.444 13.444 0 0 1 1.024 9.02c-.03-.089-.119-.331-.267-.727-.148-.396-.25-.67-.304-.824a12.1 12.1 0 0 1-.216-.705 6.416 6.416 0 0 1-.185-.854A5.726 5.726 0 0 1 0 5.132z"/>
                    </g>
                  </svg>
                  <p>Call<br />
                  <a href="tel:+61391912544">+61 3 9191 2544</a></p>
                </div>
                <div className="col">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
                    <g fill="none" fillRule="evenodd" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                      <path d="M1 1h20v16H1z"/>
                      <path d="M21 3l-10 7L1 3"/>
                    </g>
                  </svg>
                  <p>Email<br />
                  <a href="mailto:info@ramus.com.au">info@ramus.com.au</a><br />
                  <a href="mailto:media@ramus.com.au">media@ramus.com.au</a></p>
                </div>
                <div className="col">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20">
                    <path fill={color} fillRule="nonzero" d="M7 0C3.13 0 0 3.13 0 7c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z"/>
                  </svg>
                  <p>Visit<br />
                  11/131 Hyde Street<br />
                  Footscray, VIC 3011, Australia</p>
                </div>
              </div>
              <div className="row row--top">
                <h3 className="headquaters">For Europe & Middle East enquiries:</h3>
              </div>
              <div className="row row--middle">
                <div className="col">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                    <g fill={color} fillRule="evenodd">
                      <path id="a" d="M0 5.132c0-.91.252-1.83.757-2.76.554-1 1.079-1.604 1.573-1.812A5.897 5.897 0 0 1 3.347.248C3.777.15 4.127.1 4.394.1c.138 0 .242.015.311.045.179.059.44.435.787 1.128.109.188.257.455.445.801.189.347.362.66.52.943.158.282.312.547.46.794.03.04.116.163.26.371.143.208.25.383.32.527.068.143.103.284.103.423 0 .198-.141.445-.423.742a7.236 7.236 0 0 1-.92.817 8.94 8.94 0 0 0-.92.786c-.283.277-.424.505-.424.683 0 .09.025.2.074.334.05.134.092.235.127.304.034.07.104.188.207.357.104.168.161.262.171.282.752 1.355 1.613 2.518 2.583 3.488.97.97 2.133 1.83 3.488 2.583.02.01.114.067.282.17.169.104.287.174.357.208.069.035.17.077.304.127.133.049.245.074.334.074.178 0 .406-.141.683-.423a8.94 8.94 0 0 0 .786-.92c.248-.332.52-.639.817-.921.297-.282.544-.423.742-.423.139 0 .28.035.423.104.144.07.32.176.527.319.208.144.331.23.371.26.247.148.512.302.794.46.282.158.596.331.943.52.346.188.613.336.801.445.693.346 1.07.608 1.128.787.03.069.045.173.045.311 0 .267-.05.616-.148 1.047-.1.43-.203.77-.312 1.017-.208.494-.812 1.019-1.811 1.573-.93.505-1.85.757-2.761.757-.267 0-.527-.017-.78-.052a6.416 6.416 0 0 1-.853-.186 12.1 12.1 0 0 1-.705-.215 58.453 58.453 0 0 1-.824-.304 31.626 31.626 0 0 0-.727-.267 13.444 13.444 0 0 1-2.598-1.232c-1.266-.782-2.575-1.848-3.926-3.2-1.35-1.35-2.417-2.659-3.199-3.925A13.444 13.444 0 0 1 1.024 9.02c-.03-.089-.119-.331-.267-.727-.148-.396-.25-.67-.304-.824a12.1 12.1 0 0 1-.216-.705 6.416 6.416 0 0 1-.185-.854A5.726 5.726 0 0 1 0 5.132z"/>
                    </g>
                  </svg>
                  <p>Sahin Saylik<br />
                    <a href="tel:+905337081010">+90 533 708 1010</a></p>
                </div>
                <div className="col">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
                    <g fill="none" fillRule="evenodd" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                      <path d="M1 1h20v16H1z"/>
                      <path d="M21 3l-10 7L1 3"/>
                    </g>
                  </svg>
                  <p>Email<br />
                    <a href="mailto:sahin@ramus.com.au">sahin@ramus.com.au</a><br />
                  </p>
                </div>
              </div>
              <div className="row row--bottom">
                <div className="col">
                  <p>{this.state.time}</p>
                  <div dangerouslySetInnerHTML={{ __html: this.props.footerSettings.footerContent }} />
                  <p><Link to='/privacy-policy'>Privacy Policy</Link></p>
                </div>
                <div className="col">
                  <p><a onClick={this._toggleNewsletter}>Join our Newsletter</a>,</p>
                  <p className="social"><a href="https://www.youtube.com/channel/UCK8bcYo9Db1sX5VIwZHtLNg" target="_blank" rel="noopener noreferrer">Youtube</a>, <a href="https://www.facebook.com/RamusIllumination/" target="_blank" rel="noopener noreferrer">Facebook</a>, <a href="https://www.instagram.com/ramusillumination/" target="_blank" rel="noopener noreferrer">Instagram</a></p>
                </div>
              </div>
            </div>
          </footer>
        </Fade>
        <div className={ this.state.newsletterActive ? 'newsletter active' : 'newsletter' }>
          <div className={ this.state.result === 'success' ? 'newsletter__inner success' : 'newsletter__inner' }>
            <div className="newsletter__top">
              <p>Join our mailing list and stay informed, we’ll send you our favourite news and latest updates. <span>Check out our last email campaign <a href={this.props.footerSettings.newsletter} rel="noopener noreferrer" target="_blank">here</a>.</span></p>
              <svg viewBox="0 0 32 32" onClick={this._toggleNewsletter}>
                <g fill="none" fillRule="nonzero" stroke="#000" strokeLinecap="square" strokeWidth="2">
                  <path d="M2.27 2.27l27.669 27.669M2 30L30 2"/>
                </g>
              </svg>
              { this.state.msg !== '' &&
                <p className="result" dangerouslySetInnerHTML={{ __html: this.state.msg }} />
              }
            </div>
          </div>
          { this.state.result !== 'success' &&
            <div className="newsletter__form">
              <form
                name="newsletter"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this._handleSubmit}>
                <input type="hidden" name="form-name" value="newsletter" />
                <input type="hidden" name="bot-field" />
                <input className="fname" type="text" onChange={this._handleChange} placeholder="First Name" name="fname" />
                <input className="lname" type="text" onChange={this._handleChange} placeholder="Last Name" name="lname" />
                <input className="email" type="text" onChange={this._handleChange} name="email" placeholder="Email Address" />
                <input className="submit" type="submit" value="Subscribe" />
              </form>
            </div>
          }

        </div>
      </div>
    )
  }
}

export default Footer
